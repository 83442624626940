import React, { memo, useContext, useEffect } from "react";
import { Badge, Button, ButtonGroup } from "@shopify/polaris";
import { ProfileContext } from "@/Context/ProfileContext";

const FrillWidget = memo(({ widgetKey, label, selector, frame }) => {
  const { profileData } = useContext(ProfileContext);
  const [count, setCount] = React.useState(0);
  useEffect(() => {
    if (!profileData?.frillSSOToken) return;
    let widget;
    const config = {
      key: widgetKey,
      ssoToken: profileData?.frillSSOToken,
      callbacks: {
        onReady: (frillWidget) => {
          widget = frillWidget;
        },
        onBadgeCount: ({ count }) => {
          setCount(count);
        },
      },
    };

    if ("Frill" in window) {
      widget = window.Frill.widget(config);
    } else {
      window.Frill_Config = window.Frill_Config || [];
      window.Frill_Config.push(config);
    }

    return () => {
      widget?.destroy();
      if (window.Frill_Config) {
        window.Frill_Config = window.Frill_Config.filter((c) => c !== config);
      }
    };
  }, [profileData]);

  if (frame) {
    return <></>;
  }

  return (
    <ButtonGroup>
      <div className={selector}>
        <Button>
          {label} {count !== 0 && <Badge tone="attention">{count}</Badge>}
        </Button>
      </div>
    </ButtonGroup>
  );
});

export default FrillWidget;
