import { t } from "i18next";

export const getNavigationLinks = () => [
  {
    label: t("mocks.navigation.Animation"),
    destination: "/animationSetting",
  },
  {
    label: t("mocks.navigation.Contacts"),
    destination: "/contacts",
  },
  {
    label: t("mocks.navigation.Custom Css"),
    destination: "/animation",
  },
  {
    label: t("mocks.navigation.Price"),
    destination: "/pricing",
  },
  {
    label: t("mocks.navigation.Settings"),
    destination: "/settings",
  },
  // {
  //   label: t("mocks.navigation.Feedback"),
  //   destination: "/feedback",
  // },
];
