import React, { useCallback, useContext, useEffect, useState } from "react";
import Appointlet from "@appointlet/appointlet.js";
import "@appointlet/appointlet.js/dist/appointlet.min.css";
import { Button, ButtonGroup } from "@shopify/polaris";
import { ProfileContext } from "@/Context/ProfileContext";

export default function Meeting(props) {
  const { page, button } = props;
  const { profileData } = useContext(ProfileContext);

  useEffect(() => {
    if (!button) {
      const first_name = profileData?.storeName || "";
      const email = profileData?.email || "";
      const appointlet = new Appointlet(`${page}?first_name=${first_name}&email=${email}&last_name=%20`);
      appointlet.inlineEmbed(document.getElementById("place-to-put-the-scheduling-page"));
    }
  }, [profileData]);

  const openMeeting = useCallback(() => {
    const first_name = profileData?.storeName || "";
    const email = profileData?.email || "";
    const appointlet = new Appointlet(`${page}?first_name=${first_name}&email=${email}&last_name=%20`);
    appointlet.openModal();
  }, [page, profileData]);

  if (button) {
    return (
      <ButtonGroup>
        <Button onClick={openMeeting} {...props}>
          {button}
        </Button>
      </ButtonGroup>
    );
  }

  return <div id="place-to-put-the-scheduling-page"></div>;
}
