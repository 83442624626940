import { useContext, useEffect, useRef } from "react";
import { useAppBridge } from "@shopify/app-bridge-react";
import { getSessionToken } from "@shopify/app-bridge-utils";
import { Redirect } from "@shopify/app-bridge/actions";
import axios from "axios";
import { LoadingContext } from "@/Context/LoadingContext";
import { ToastContext } from "@/Context/ToastContext";
import { logger } from "@/Services/Logger/Index";
import { handleError, localStorage } from "@/Utils/Index";

const instance = axios.create({
  baseURL: "/apps/api/", // Replace with your actual Shopify app base URL
  headers: {
    "Content-Type": "application/json",
  },
});

export function setAuthHeader(accessToken) {
  try {
    instance.defaults.headers.common["authorization"] = `Bearer ${accessToken}`;
  } catch (e) {
    return undefined;
  }
}

function checkHeadersForReauthorization(headers) {
  if (headers && headers.get("X-Shopify-API-Request-Failure-Reauthorize") === "1") {
    const authUrlHeader = headers.get("X-Shopify-API-Request-Failure-Reauthorize-Url");
    return authUrlHeader;
  } else {
    return;
  }
}

export function setAdminAuthHeader() {
  try {
    instance.defaults.headers.common["authorizationAdmin"] = localStorage()?.getItem("adminAccessToken");
  } catch (e) {
    return undefined;
  }
}

export function setAdminPanelAuthHeader() {
  try {
    instance.defaults.headers.common["authorization"] = localStorage()?.getItem("adminPanelAccessToken");
  } catch (e) {
    return undefined;
  }
}

export function useAuthenticatedFetch() {
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const { showToast } = useContext(ToastContext);
  const app = useRef();

  useEffect(() => {
    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        let redirectUrl = checkHeadersForReauthorization(error?.response?.headers);
        if (redirectUrl) {
          const redirect = Redirect.create(app.current);
          redirect.dispatch(Redirect.Action.APP, redirectUrl || `/exitframe`);
          showToast(handleError(error), false);
          stopLoading();
          return Promise.reject(error);
        }
        showToast(handleError(error), true);
        stopLoading();
        return Promise.reject(error);
      }
    );
  }, [instance]);

  try {
    let adminAccessToken = localStorage()?.getItem("adminAccessToken");
    const isAdminRoute = window.location.pathname.includes("/admin");
    if (isAdminRoute) {
      return {
        get: async (url, showIsLoading = true) => {
          showIsLoading && startLoading();
          setAdminPanelAuthHeader();
          const response = await instance.get(url);
          showIsLoading && stopLoading();
          return response.data;
        },
        post: async (url, data, showIsLoading = true, headers = {}) => {
          showIsLoading && startLoading();
          setAdminPanelAuthHeader();
          instance.defaults.headers = { ...instance.defaults.headers, ...headers };
          const response = await instance.post(url, data);
          showIsLoading && stopLoading();
          return response.data;
        },
        put: async (url, data, showIsLoading = true) => {
          showIsLoading && startLoading();
          setAdminPanelAuthHeader();
          const response = await instance.put(url, data);
          showIsLoading && stopLoading();
          return response.data;
        },
        delete: async (url, showIsLoading = true) => {
          showIsLoading && startLoading();
          setAdminPanelAuthHeader();
          const response = await instance.delete(url);
          showIsLoading && stopLoading();
          return response.data;
        },
      };
    } else if (!adminAccessToken) {
      app.current = useAppBridge();
      return {
        get: async (url, showIsLoading = true) => {
          showIsLoading && startLoading();
          const accessToken = await getSessionToken(app.current);
          setAuthHeader(accessToken);
          const response = await instance.get(url);
          showIsLoading && stopLoading();
          return response.data;
        },
        post: async (url, data, showIsLoading = true, headers = { "Content-Type": "application/json" }) => {
          showIsLoading && startLoading();
          const accessToken = await getSessionToken(app.current);
          setAuthHeader(accessToken);
          instance.defaults.headers = { ...instance.defaults.headers, ...headers };
          const response = await instance.post(url, data);
          showIsLoading && stopLoading();
          return response.data;
        },
        put: async (url, data, showIsLoading = true) => {
          showIsLoading && startLoading();
          const accessToken = await getSessionToken(app.current);
          setAuthHeader(accessToken);
          const response = await instance.put(url, data);
          showIsLoading && stopLoading();
          return response.data;
        },
        delete: async (url, showIsLoading = true) => {
          showIsLoading && startLoading();
          const accessToken = await getSessionToken(app.current);
          setAuthHeader(accessToken);
          const response = await instance.delete(url);
          showIsLoading && stopLoading();
          return response.data;
        },
      };
    } else {
      return {
        get: async (url, showIsLoading = true) => {
          showIsLoading && startLoading();
          setAdminAuthHeader();
          const response = await instance.get(url);
          showIsLoading && stopLoading();
          return response.data;
        },
        post: async (url, data, showIsLoading = true, headers = { "Content-Type": "application/json" }) => {
          showIsLoading && startLoading();
          setAdminAuthHeader();
          instance.defaults.headers = { ...instance.defaults.headers, ...headers };
          const response = await instance.post(url, data);
          showIsLoading && stopLoading();
          return response.data;
        },
        put: async (url, data, showIsLoading = true) => {
          showIsLoading && startLoading();
          setAdminAuthHeader();
          const response = await instance.put(url, data);
          showIsLoading && stopLoading();
          return response.data;
        },
        delete: async (url, showIsLoading = true) => {
          showIsLoading && startLoading();
          setAdminAuthHeader();
          const response = await instance.delete(url);
          showIsLoading && stopLoading();
          return response.data;
        },
      };
    }
  } catch (error) {
    logger.error(error);
  }
}
