import { createContext, useCallback, useEffect, useState } from "react";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { logger } from "@/Services/Logger/Index";

export const ProfileContext = createContext();
export const ProfileContextProvider = ({ children }) => {
  const [profileData, setProfileData] = useState(undefined);
  const [appStatus, setAppStatus] = useState(undefined);
  const [isProfileLoadding, setIsProfileLoadding] = useState(false);
  const fetch = useAuthenticatedFetch();
  const fetchProfileData = async () => {
    try {
      setIsProfileLoadding(true);
      const res = await fetch.get("profile");
      if (res?.data) {
        logger.identifyUser({
          id: res?.data._id,
          email: res?.data.email || "email",
          shopUrl: res?.shopUrl,
        });
        logger.identifyUserScope(res?.data);
      }
      setIsProfileLoadding(false);
      setProfileData(res?.data || undefined);
    } catch (err) {
      setIsProfileLoadding(false);
      const authErr = err?.response?.status === 403 && err?.response?.data?.message === "Don't Refresh";
      if (!authErr) {
        setProfileData({ error: true });
      }
    }
  };

  const getAppBlockStatus = useCallback(async () => {
    const response = await fetch.get("app-block-status");
    setAppStatus(response.data);
  }, [profileData]);

  useEffect(() => {
    fetchProfileData();
    getAppBlockStatus();
  }, []);

  const updateProfileData = (e) => {
    setProfileData(e);
  };

  return (
    <ProfileContext.Provider
      value={{ profileData, isProfileLoadding, updateProfileData, getAppBlockStatus, appStatus }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
