import { Button } from "@shopify/polaris";
import { Icon } from "@shopify/polaris";
import { LegalMajor, NoteMinor, ThumbsUpMajor } from "@shopify/polaris-icons";
import { t } from "i18next";
import {
  ABLogo,
  CCLogo,
  Google,
  Help,
  LinkifyLogo,
  SearchOptimize,
  SeoLogo,
  StoreOptimize,
  StoreSpeedOptimize,
  YouTube,
} from "@/Assets/Index";
import FrillEmbeddedWidget from "@/Components/Common/FrillEmbeddedWidget";
import Meeting from "@/Components/Common/Meeting";

export const AppList = [
  {
    title: "Webrex SEO: AI, Speed & Schema",
    tagLine: "Optimize your Store by align correct SEO to improve organic ranking, boost visibility",
    link: "https://apps.shopify.com/breadcrumbs-schemas",
    image: SeoLogo,
    hide: true,
  },
  {
    title: "WebRex Multi Announcement Bar",
    tagLine: "Boost sales with customizable bars for announcements, free shipping, and countdown timers",
    link: "https://apps.shopify.com/announcement-bar-with-slider",
    image: ABLogo,
  },
  {
    title: "Linkify ‑ Backlink SEO Booster",
    tagLine: "Supercharge SEO with top-tier backlinks from high DA sites. Boost rankings, traffic, and authority",
    link: "https://apps.shopify.com/linkify-app",
    image: LinkifyLogo,
  },
  {
    title: "Webrex ‑ Currency Converter",
    tagLine: "Solution for your International Selling that will help to show converted prices, local currency",
    link: "https://apps.shopify.com/currency-converter-11",
    image: CCLogo,
  },
];

export const getSupportsList = () => [
  {
    title: t("mocks.app.Schedule Meeting"),
    tagLine: t(
      "mocks.app.Book a dedicated time slot with our Shopify app experts to address your queries and ensure optimal performance."
    ),
    action: t("mocks.app.Schedule Meeting"),
    link: "https://appt.link/webrex-studio/webrex-seo-app-demo-and-support",
    meeting: true,
    image: Google,
    children: (
      <Meeting
        page="https://appt.link/webrex-studio/webrex-seo-app-demo-and-support"
        button={t("mocks.app.Schedule Meeting")}
      ></Meeting>
    ),
  },
  {
    title: t("mocks.app.Instant Google Meet call"),
    tagLine: t(
      "mocks.app.Need immediate assistance? Jump on a Google Meet call now with our Shopify app specialists for real-time support."
    ),
    action: t("mocks.app.Call Now"),
    link: "https://appt.link/webrex-studio/webrex-seo-app-demo-and-support",
    image: Google,
    hide: true,
  },
  {
    title: t("mocks.app.Help Center"),
    tagLine: t("mocks.app.Find a solution to your problem with Webrex help center documentations and tutorials."),
    action: t("mocks.app.Visit Help Center"),
    link: "https://webrexstudio.gitbook.io/seo-json",
    children: (
      <Button target="_blank" url="https://webrexstudio.gitbook.io/seo-json">
        {t("mocks.app.Visit Help Center")}
      </Button>
    ),
    image: Help,
  },
  {
    title: t("mocks.app.Webrex Youtube Chanel"),
    tagLine: t("mocks.app.Check our comprehensive video tutorials about webrex all application."),
    action: t("mocks.app.Go to Youtube Channel"),
    link: "https://webrexstudio.gitbook.io/seo-json",
    image: YouTube,
    hide: true,
  },
];

export const getFrillWidgetList = () => [
  {
    title: t("mocks.app.Feature Suggestion & Voting Hub"),
    tagLine: t(
      "mocks.app.Propose innovative features and vote on ideas contributed by the community in this interactive hub."
    ),
    button: (
      <FrillEmbeddedWidget
        widgetKey="2250288d-d62d-47f5-88a0-337f95a5d4da"
        label={t("mocks.app.Feature Request or Vote")}
        selector="request-feature"
      />
    ),
    icon: <Icon source={ThumbsUpMajor} />,
  },
  {
    title: t("mocks.app.Latest Feature Releases"),
    tagLine: t(
      "mocks.app.Stay informed with announcements detailing the newest features and updates added to our product."
    ),
    button: (
      <FrillEmbeddedWidget
        widgetKey="362fa1e9-69cc-4d4e-8fd7-1888355e3c64"
        label={t("mocks.app.Announcements")}
        selector="announcements"
      />
    ),
    icon: <Icon source={NoteMinor} />,
  },
  {
    title: t("mocks.app.Development Roadmap Overview"),
    tagLine: t(
      "mocks.app.Visualize our strategic development plan with a roadmap highlighting upcoming features and improvements."
    ),
    button: (
      <FrillEmbeddedWidget
        widgetKey="cd2fe424-01a9-41f5-aa84-14fd752a4081"
        label={t("mocks.app.Roadmap")}
        selector="roadmap"
      />
    ),
    icon: <Icon source={LegalMajor} />,
  },
];

export const getServicesList = () => [
  {
    title: t("mocks.app.SEO Service"),
    tagLine: t(
      "mocks.app.Boost your Shopify store's visibility on search engines with our specialized SEO strategies."
    ),
    action: t("mocks.app.Schedule Meeting"),
    link: "https://appt.link/webrex-studio/seo-services",
    image: SearchOptimize,
    children: (
      <Meeting
        page="https://appt.link/webrex-studio/seo-services"
        button={t("mocks.app.Schedule Meeting")}
      ></Meeting>
    ),
  },
  {
    title: "Store page speed optimization",
    tagLine:
      "Enhance your Shopify store's loading speed for a seamless shopping experience and improved conversions.",
    action: t("mocks.app.Schedule Meeting"),
    link: "https://appt.link/webrex-studio/store-page-speed-pptimization",
    image: StoreSpeedOptimize,
    children: (
      <Meeting
        page="https://appt.link/webrex-studio/seo-service"
        button={t("mocks.app.Schedule Meeting")}
      ></Meeting>
    ),
  },
  {
    title: "Store page Customization",
    tagLine:
      "Tailor your Shopify store's design to resonate with your brand, ensuring a unique and captivating customer journey.",
    action: t("mocks.app.Schedule Meeting"),
    link: "https://appt.link/webrex-studio/store-page-speed-pptimization",
    image: StoreOptimize,
    hide: true,
  },
];

const uninstallOptions = () => [
  { label: t(`Uninstall.Did not meet my expectations`), value: " Did not meet my expectations" },
  { label: t(`Uninstall.Feature Limitations`), value: "Feature Limitations" },
  { label: t(`Uninstall.Banner doesn't set in my app`), value: "Banner doesn't set in my app" },
  { label: t(`Uninstall.Banner is not show properly`), value: "Banner is not show properly" },
  { label: t(`Uninstall.Confusing Set Multi Position and Multi Animation`), value: "Confusing Set Multi Position and Multi Animation" },
  { label: t(`Uninstall.Not getting how to use or setup`), value: "Not getting how to use or setup" },
  { label: t(`Uninstall.Poor customer support`), value: "Poor customer support" },
  { label: t(`Uninstall.Performance issues`), value: "Performance issues" },
  { label: t(`Uninstall.Other`), value: "Other" },
];

export const getUninstallFormField = (isHidden) => [
  {
    id: "reason",
    name: "reason",
    label: t("Uninstall.Please Choose a UnInstallation Reason"),
    labelPosition: "left",
    type: "select",
    options: [...uninstallOptions()],
    placeholder: t("Uninstall.Select a reason"),
    validated: true,
    errormsg: t(`Uninstall.Please Choose at least one reason`),
  },
  {
    id: "reasonValue",
    name: "reasonValue",
    label: t("Uninstall.Mention Your Specific Reason Here"),
    type: "text",
    validated: isHidden,
    errormsg: t(`Uninstall.Mention Your Specific Reason Here`),
  },

  {
    id: "support",
    name: "support",
    label: t("Uninstall.Get Help from Our Support Team"),
    type: "checkbox",
  },
];

export const uninstallFormValue = { reason: "", reasonValue: "" };
