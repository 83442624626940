import React, { useCallback, useContext, useEffect, useState } from "react";
import { BlockStack, Card, Collapsible, FormLayout, Icon, InlineStack, Link, Text } from "@shopify/polaris";
import { ChevronDownMinor, ChevronRightMinor } from "@shopify/polaris-icons";
import { Field, FieldArray, Formik } from "formik";
import { t } from "i18next";
import { cloneDeep, isEqual } from "lodash";
import { ToastContext } from "@/Context/ToastContext";
import CreateValidationSchema from "./CreateValidationSchema";
import { Add, Delete, DependOn, DynamicSection, SaveButton } from "./FormComponent";

const RenderComponent = ({ field, index, commonProps, values, errors, touched }) => {
  return (
    <BlockStack gap={400}>
      <DynamicSection key={field.name + index} field={field}>
        <FormLayout>
          {[...Array(Math.ceil(field?.subfields?.length / field?.groupSize))].map((_, gIndex) => (
            <FormLayout.Group key={gIndex} condensed>
              {field?.subfields
                ?.slice(gIndex * field.groupSize, gIndex * field.groupSize + field?.groupSize)
                .map((subfield) => {
                  return (
                    <MainComponent
                      field={subfield}
                      name={`${commonProps.name}[${index}]`}
                      id={`${commonProps.id}[${index}]`}
                      key={`${commonProps.id}[${index}].${subfield.name}`}
                      values={values[field.name]?.[index] ? values[field.name][index] : {}}
                      errors={errors[field.name]?.[index] ? errors[field.name][index] : {}}
                      touched={touched[field.name]?.[index] ? touched[field.name][index] : {}}
                    />
                  );
                })}
            </FormLayout.Group>
          ))}
        </FormLayout>
      </DynamicSection>
      <Text />
    </BlockStack>
  );
};

const MainComponent = (props) => {
  const { name, id, field, errors, values, touched } = props;
  let commonProps = {};
  if (field.name) {
    commonProps = {
      id: name ? `${name}.${field.id}` : field.id,
      name: name ? `${name}.${field.name}` : field.name,
      key: name ? `${name}.${field.name}` : field.name,
    };
  } else {
    commonProps = { id: name, name: name, key: name };
  }
  if (!field.nested) {
    return (
      <Field
        field={{ ...field, ...commonProps }}
        component={DependOn}
        currentObj={values}
        value={values[field.name]}
        error={errors[field.name]}
        touch={touched[field.name]}
        key={field.name}
      />
    );
  } else if (field.nested === "array") {
    field.groupSize = field.groupSize ? field.groupSize : 2;
    const [selectedIds, setSelectedIds] = useState([]);
    const [closeIndex, setCloseIndex] = useState();
    const openCollapsible = useCallback(
      (index) => {
        if (index == closeIndex) setCloseIndex();
        setSelectedIds((prevSelectedIds) => [...prevSelectedIds, index]);
      },
      [closeIndex]
    );
    const closeCollapsible = (index) => {
      setSelectedIds((prev) => prev.filter((id) => id !== index));
      setCloseIndex(index);
    };

    const checkOpenCollapsible = useCallback(
      (index) => {
        const arrayLength = values[field.name]?.length - 1;
        if ((index === arrayLength || selectedIds.includes(index)) && closeIndex !== index) return true;
        else return false;
      },
      [selectedIds, values, closeIndex]
    );

    const showErrorWarning = useCallback(
      (index) => {
        if (errors[field.name]?.[index] && touched[field.name]?.[index]) return true;
        else return false;
      },
      [errors, touched]
    );

    return (
      <FieldArray key={field.id} name={commonProps.name}>
        {({ push, remove }) => (
          <React.Fragment key={field.id}>
            <BlockStack gap="200">
              {field.label && <Text fontWeight="bold">{field.label}</Text>}
              {values[field.name]?.map((nestedItem, index) => (
                <div key={field.name + index}>
                  {field?.showCollapsible ? (
                    <Card>
                      <BlockStack gap={200}>
                        <InlineStack align="space-between">
                          <InlineStack gap={600}>
                            <Link
                              monochrome
                              removeUnderline
                              onClick={() =>
                                checkOpenCollapsible(index) ? closeCollapsible(index) : openCollapsible(index)
                              }
                            >
                              {eval(field.CollapsibleLabel) && (
                                <Text fontWeight="bold">{eval(field?.CollapsibleLabel)}</Text>
                              )}
                            </Link>
                            {showErrorWarning(index) && (
                              <Text variant="critical">{t("common.commonForm.Missing required field.")}</Text>
                            )}
                          </InlineStack>
                          <InlineStack gap={100}>
                            {!field.hideDeletebtn && <Delete {...field} index={index} remove={remove} />}
                            <Link
                              monochrome
                              removeUnderline
                              onClick={() =>
                                checkOpenCollapsible(index) ? closeCollapsible(index) : openCollapsible(index)
                              }
                            >
                              <Icon source={checkOpenCollapsible(index) ? ChevronDownMinor : ChevronRightMinor} />
                            </Link>
                          </InlineStack>
                        </InlineStack>
                        <Collapsible
                          open={checkOpenCollapsible(index)}
                          id="basic-collapsible"
                          transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
                          expandOnPrint
                        >
                          <RenderComponent
                            field={field}
                            index={index}
                            commonProps={commonProps}
                            values={values}
                            errors={errors}
                            touched={touched}
                          />
                        </Collapsible>
                      </BlockStack>
                    </Card>
                  ) : (
                    <BlockStack gap={100}>
                      <InlineStack align="space-between" blockAlign="center">
                        {field?.label && (
                          <Text>
                            {field?.label} - {index + 1}
                          </Text>
                        )}
                        {!field.hideDeletebtn && <Delete {...field} index={index} remove={remove} />}
                      </InlineStack>
                      <RenderComponent
                        field={field}
                        index={index}
                        commonProps={commonProps}
                        values={values}
                        errors={errors}
                        touched={touched}
                      />
                    </BlockStack>
                  )}
                </div>
              ))}
              {!field.hideAddbtn && <Add {...field} push={push} />}
            </BlockStack>
          </React.Fragment>
        )}
      </FieldArray>
    );
  } else if (field.nested === "object") {
    field.groupSize = field.groupSize ? field.groupSize : 2;
    return (
      <React.Fragment key={field.id}>
        <BlockStack gap="200">
          {field.label && <Text as="p">{field.label}</Text>}
          <DynamicSection field={field}>
            <FormLayout>
              {[...Array(Math.ceil(field?.subfields?.length / field?.groupSize))].map((_, gIndex) => (
                <FormLayout.Group key={gIndex} condensed>
                  {field?.subfields
                    ?.slice(gIndex * field.groupSize, gIndex * field.groupSize + field.groupSize)
                    .map((subfield) => (
                      <MainComponent
                        field={subfield}
                        {...commonProps}
                        key={`${commonProps.key}-${subfield.id}`}
                        values={values[field.name] ? values[field.name] : {}}
                        errors={errors[field.name] ? errors[field.name] : {}}
                        touched={touched[field.name] ? touched[field.name] : {}}
                      />
                    ))}
                </FormLayout.Group>
              ))}
            </FormLayout>
          </DynamicSection>
          {field.helpText && (
            <Text as="p" tone="subdued" variant="bodySm">
              {field.helpText}
            </Text>
          )}
        </BlockStack>
      </React.Fragment>
    );
  } else if (field.nested === "group") {
    field.groupSize = field.groupSize ? field.groupSize : 2;
    return (
      <React.Fragment key={field.id}>
        <BlockStack gap="200">
          <Text as="p">{field.label}</Text>
          <DynamicSection field={field}>
            <FormLayout>
              {[...Array(Math.ceil(field?.subfields?.length / field?.groupSize))].map((_, gIndex) => (
                <FormLayout.Group key={gIndex} condensed>
                  {field?.subfields
                    ?.slice(gIndex * field.groupSize, gIndex * field.groupSize + field.groupSize)
                    .map((subfield, index) => {
                      return (
                        <MainComponent
                          field={subfield}
                          {...commonProps}
                          key={index}
                          values={values ? values : {}}
                          errors={errors ? errors : {}}
                          touched={touched ? touched : {}}
                        />
                      );
                    })}
                </FormLayout.Group>
              ))}
            </FormLayout>
          </DynamicSection>
        </BlockStack>
      </React.Fragment>
    );
  }
};

const CommonForm = ({
  formFields,
  initialValues,
  onSubmit,
  onFormChange,
  formRef,
  isSave,
  isPremium,
  noValueChanged = true,
  noCompare = true,
  label,
  enableReinitialize,
}) => {
  const validationSchema = CreateValidationSchema(formFields);
  const { showToast } = useContext(ToastContext);

  return (
    <Formik
      enableReinitialize={enableReinitialize || false}
      initialValues={cloneDeep(initialValues)}
      onSubmit={(values, formikBag) => {
        if (noValueChanged && isEqual(values, initialValues)) {
          showToast("No values are changed. Try changing values.");
        } else {
          onSubmit(values);
        }
      }}
      validationSchema={validationSchema}
      innerRef={formRef}
    >
      {({ values, handleSubmit, errors, touched, setFieldValue }) => {
        {
          onFormChange &&
            useEffect(() => {
              if (noCompare && isEqual(values, initialValues)) return;
              onFormChange(values);
            }, [values]);
        }
        return (
          <form onSubmit={handleSubmit}>
            <FormLayout>
              {formFields.map((field, index) => {
                return (
                  <MainComponent field={field} values={values} errors={errors} touched={touched} key={index} />
                );
              })}
              <SaveButton isSave={isSave} isPremium={isPremium} label={label}></SaveButton>
            </FormLayout>
          </form>
        );
      }}
    </Formik>
  );
};

export default CommonForm;
