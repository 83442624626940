export const activePlanformFields = [
  {
    id: "activePlanName",
    name: "planName",
    labelPosition: "right",
    validated: false,
    type: "text",
    placeholder: "Add Plan Name",
  },
  {
    id: "planPrice",
    name: "planPrice",
    labelPosition: "right",
    validated: false,
    type: "text",
    placeholder: "Add Plan Price",
  },
];
export const activePlaninitialValues = {
  planName: "",
  planPrice: "",
};
